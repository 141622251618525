var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"holder"},[_c('div',{staticClass:"dashboard_holder"},[_c('SmallNav'),(_vm.hideDiv)?_c('div',[_c('PageLoader')],1):_c('div',{staticClass:"container wrap_row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 cos-sm-12"},[_c('div',{staticClass:"property_details_holder card"},[(_vm.switchData)?_c('div',{staticClass:"plan_sign"},[_c('div',[_c('p',[_vm._v("Current Plan")]),(_vm.switchData)?_c('p',[_vm._v(" "+_vm._s(_vm.switchData.switchableModes[0].present_mode)+" ")]):_vm._e()]),_c('div',[_c('p',[_vm._v("Next Rent")]),(_vm.nextRentDays)?_c('p',[_vm._v(_vm._s(_vm.nextRentDays)+" Days Left")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"property_img"},[(_vm.switchData)?_c('img',{attrs:{"src":_vm.switchData.propertyObject.oneimageurl,"alt":"property image"}}):_vm._e()])]),_c('div',{staticClass:"col-md-6 pl-md-0"},[_c('div',{staticClass:"property_details"},[(_vm.switchData)?_c('h3',[_vm._v(" "+_vm._s(_vm.switchData.propertyObject.title)+" ")]):_vm._e(),(_vm.switchData)?_c('h6',[_vm._v(" "+_vm._s(_vm.switchData.propertyObject.address)+" ")]):_vm._e(),(_vm.switchData)?_c('h4',[_vm._v(" ₦"+_vm._s(Number( _vm.switchData.propertyObject.price ).toLocaleString())+" Annually ")]):_vm._e(),(_vm.switchData)?_c('p',[_vm._v(" Payment due date: "),_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.switchData.duedatejoined)))])]):_vm._e()])])])])]),_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('div',{staticClass:"plan_holder card container"},_vm._l((_vm.switchData.switchableModes),function(item,index){return _c('div',{key:index},[_c('button',{staticClass:"btn play_btn",class:{
                    active_plan:
                      _vm.switchData.switchableModes[0].present_mode ===
                      item.destination_mode,
                    plan_btn:
                      _vm.switchData.switchableModes[0].present_mode !=
                      item.destination_mode,
                    activeNewPlan: item.destination_mode === _vm.isNewActive
                  },attrs:{"disabled":item.disable === true},on:{"click":function($event){return _vm.selectPlan(item)}}},[_c('span',{staticClass:"plan_amount_btn"},[_vm._v("₦"+_vm._s(Number(item.price).toLocaleString()))]),_c('span',{staticClass:"plan_duration"},[_vm._v(_vm._s(item.destination_mode)+" "),(
                        _vm.switchData.switchableModes[0].present_mode ==
                          item.destination_mode
                      )?_c('span',[_vm._v("(Current)")]):_vm._e()]),(
                      _vm.switchData.switchableModes[0].present_mode ==
                        item.destination_mode
                    )?_c('span',{staticClass:"plan_icon"},[_c('img',{attrs:{"src":require("../assets/images/circle-checked.svg"),"alt":"current"}})]):_vm._e(),(
                      item.disable != true &&
                        _vm.switchData.switchableModes[0].present_mode !=
                          item.destination_mode
                    )?_c('span',{staticClass:"plan_icon"},[_c('img',{attrs:{"src":require("../assets/images/circle-unchecked.svg"),"alt":""}})]):_vm._e(),(
                      item.disable === true &&
                        _vm.switchData.switchableModes[0].present_mode !=
                          item.destination_mode
                    )?_c('span',{staticClass:"plan_icon"},[_c('img',{attrs:{"src":require("../assets/images/luck.svg"),"alt":""}})]):_vm._e()])])}),0)])]),_c('div',{staticClass:"payment_schedule_holder col-md-11"},[_vm._m(0),_c('div',{staticClass:"schedule_period"},_vm._l((_vm.schedule),function(item,index){return _c('div',{key:index,staticClass:"color__item"},[_c('h6',[(item.status == '1')?_c('img',{attrs:{"src":require("../assets/images/green-check-round.svg"),"alt":"paid"}}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("moment")(item.duedate))+" "),(item.daysleft > 0)?_c('span',[_vm._v("("+_vm._s(item.daysleft)+" days away)")]):_vm._e()]),_c('small',{class:[item.status == '1' ? 'green_pay' : '']},[_vm._v("₦"+_vm._s(Number(item.amount).toLocaleString()))])])}),0),_c('div',{staticClass:"schedule_footer"},[_c('h5',[_vm._v("View All")]),_c('div',[(_vm.paid != '')?_c('h3',[_vm._v(" Total Paid: ₦"+_vm._s(Number(_vm.paid).toLocaleString())+" ")]):_vm._e(),(_vm.outstanding != '')?_c('h4',[_vm._v(" Total Outstanding: ₦"+_vm._s(Number(_vm.outstanding).toLocaleString())+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-11"},[_c('button',{ref:"proceed",staticClass:"btn proceed_btn",attrs:{"disabled":!_vm.isNewActive},on:{"click":function($event){return _vm.moveToAgreement(_vm.switchData.propertyObject.pin)}}},[_vm._v(" PROCEED ")])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule_header"},[_c('h5',[_vm._v("Rent Payment Schedule")]),_c('img',{attrs:{"src":require("../assets/images/switch-icon.svg"),"alt":""}})])}]

export { render, staticRenderFns }