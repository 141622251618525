<template>
  <div>
    <div class="holder">
      <div class="dashboard_holder">
        <SmallNav />
        <div v-if="hideDiv">
          <PageLoader></PageLoader>
        </div>
        <div v-else class="container wrap_row">
          <div class="row">
            <div class="col-md-7 cos-sm-12">
              <div class="property_details_holder card">
                <div class="plan_sign" v-if="switchData">
                  <div>
                    <p>Current Plan</p>
                    <p v-if="switchData">
                      {{ switchData.switchableModes[0].present_mode }}
                    </p>
                  </div>
                  <div>
                    <p>Next Rent</p>
                    <p v-if="nextRentDays">{{ nextRentDays }} Days Left</p>
                    <!-- <p v-if="nextRentDays <= 0">0 Days Left</p> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="property_img">
                      <img
                        v-if="switchData"
                        :src="switchData.propertyObject.oneimageurl"
                        alt="property image"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 pl-md-0">
                    <div class="property_details">
                      <!-- &#8358;{{ Number(paymentData.nextpayment).toLocaleString() }} -->
                      <h3 v-if="switchData">
                        {{ switchData.propertyObject.title }}
                      </h3>
                      <h6 v-if="switchData">
                        {{ switchData.propertyObject.address }}
                      </h6>
                      <h4 v-if="switchData">
                        &#8358;{{
                          Number(
                            switchData.propertyObject.price
                          ).toLocaleString()
                        }}
                        Annually
                        <!-- {{ switchData.switchableModes[0].present_mode }} -->
                      </h4>
                      <p v-if="switchData">
                        Payment due date:
                        <span> {{ switchData.duedatejoined | moment }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="plan_holder card container">
                <div
                  v-for="(item, index) in switchData.switchableModes"
                  :key="index"
                >
                  <!-- {{ switchData2.propertyObject.modeofpayment }} = {{ item.destination_mode }} -->
                  <!-- :disabled="item.disable === true" -->
                  <button
                    :disabled="item.disable === true"
                    @click="selectPlan(item)"
                    class="btn play_btn"
                    :class="{
                      active_plan:
                        switchData.switchableModes[0].present_mode ===
                        item.destination_mode,
                      plan_btn:
                        switchData.switchableModes[0].present_mode !=
                        item.destination_mode,
                      activeNewPlan: item.destination_mode === isNewActive
                    }"
                  >
                    <span class="plan_amount_btn"
                      >&#8358;{{ Number(item.price).toLocaleString() }}</span
                    >
                    <span class="plan_duration"
                      >{{ item.destination_mode }}
                      <span
                        v-if="
                          switchData.switchableModes[0].present_mode ==
                            item.destination_mode
                        "
                        >(Current)</span
                      ></span
                    >
                    <span
                      class="plan_icon"
                      v-if="
                        switchData.switchableModes[0].present_mode ==
                          item.destination_mode
                      "
                    >
                      <img
                        src="../assets/images/circle-checked.svg"
                        alt="current"
                      />
                    </span>
                    <span
                      class="plan_icon"
                      v-if="
                        item.disable != true &&
                          switchData.switchableModes[0].present_mode !=
                            item.destination_mode
                      "
                    >
                      <img src="../assets/images/circle-unchecked.svg" alt="" />
                    </span>
                    <span
                      class="plan_icon"
                      v-if="
                        item.disable === true &&
                          switchData.switchableModes[0].present_mode !=
                            item.destination_mode
                      "
                    >
                      <img src="../assets/images/luck.svg" alt="" />
                    </span>
                  </button>
                </div>

                <!-- <div>
                  <router-link to="/dashboard/rent-summary">
                    <button class="btn proceed_btn">
                      PROCEED
                    </button>
                  </router-link>
                </div> -->
              </div>
            </div>
          </div>

          <!-- Payment schedule -->
          <div class="payment_schedule_holder col-md-11">
            <div class="schedule_header">
              <h5>Rent Payment Schedule</h5>
              <img src="../assets/images/switch-icon.svg" alt="" />
            </div>
            <div class="schedule_period">
              <div
                v-for="(item, index) in schedule"
                class="color__item"
                :key="index"
              >
                <h6>
                  <img
                    v-if="item.status == '1'"
                    src="../assets/images/green-check-round.svg"
                    alt="paid"
                  />
                  {{ item.duedate | moment }}
                  <span v-if="item.daysleft > 0"
                    >({{ item.daysleft }} days away)</span
                  >
                </h6>
                <small :class="[item.status == '1' ? 'green_pay' : '']"
                  >&#8358;{{ Number(item.amount).toLocaleString() }}</small
                >
              </div>
            </div>
            <div class="schedule_footer">
              <h5>View All</h5>
              <div>
                <h3 v-if="paid != ''">
                  Total Paid: &#8358;{{ Number(paid).toLocaleString() }}
                </h3>
                <h4 v-if="outstanding != ''">
                  Total Outstanding: &#8358;{{
                    Number(outstanding).toLocaleString()
                  }}
                </h4>
              </div>
            </div>
          </div>

          <div class="col-md-11">
            <button
              ref="proceed"
              :disabled="!isNewActive"
              class="btn proceed_btn"
              @click="moveToAgreement(switchData.propertyObject.pin)"
            >
              PROCEED
            </button>
          </div>
          <!-- Payment schedule -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SmallNav from "./SmallNav";
import PageLoader from "./PageLoader.vue";
import { Service } from "../store/service";
const Api = new Service();
import moment from "moment";
import $ from "jquery";
import { EventBus } from "../main";
export default {
  name: "SwitchPlan",
  components: {
    SmallNav,
    PageLoader
    // FooterMain,
  },
  data() {
    return {
      hideDiv: false,
      // switchData: {
      //   propertyObject: {
      //     modeofpayment: "",
      //   },
      // },
      selectedPrice: "",
      switchableData: "",
      switchData: "",
      isNewActive: "",
      schedule: "",
      outstanding: "",
      paid: "",
      nextRentDays: ""
      // plan: false;
    };
  },
  mounted() {
    // this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    if (this.switchData == null) {
      this.$router.go(-1);
    }
    this.getSwitchableData();
  },
  methods: {
    selectPlan(item) {
      this.switchableData = item;
      this.selectedPrice = item.price;
      this.isNewActive = item.destination_mode;
      const elements = this.$refs.proceed;
      // const elements = document.getElementById("similar");
      const yOffset = -100;
      const y =
        elements.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      // EventBus.$emit('gotclicked', this.switchData);
      // console.log("logggggggggggg", index, price);
      // this.$root.$emit('keywordEvent', this.switchData)

      // this.$router.push({name: '/dashboard/rent-summary', query: { keyword: this.switchData }} )
    },
    async fetchPropertySchdeule() {
      const id = this.switchData.propertyid;
      this.PageLoad = true;
      this.hideDiv = true;
      // this.loader = true;
      try {
        await Api.getRequest(
          `getpaymentscheduleforpropertycurrentlyrented/${id}`
        )
          .then(res => {
            this.hideDiv = false;
            // if (res.data.error) {
            //   this.PageLoad = true;
            // }
            if (res.data.success) {
              // this.daysLeft();
              this.PageLoad = false;
              this.hideDiv = false;
              // console.log("Payment schdule", res);
              this.schedule = res.data.schedule;
              const x = this.schedule.filter(
                item => parseInt(item.status) === 0
              );
              const y = this.schedule.filter(
                item => parseInt(item.status) === 1
              );
              this.schedule.map(element => {
                const rentDueDate = element.duedate;
                // console.log("rented date", rentDueDate);
                const rentdate = new Date(rentDueDate);
                const currentDate = new Date();
                const timeDiff = rentdate.getTime() - currentDate.getTime();
                const diff = timeDiff / (1000 * 3600 * 24);
                const daysDiff = Math.round(diff);
                element.daysleft = parseInt(daysDiff);
                // console.log("checkkkk", parseInt(daysDiff));
              });
              // console.log("Staus with 0", x);
              // return this.schedule.reduce((sum, item) => sum + parseInt(item.amount), 0);
              this.outstanding = x.reduce(
                (sum, amount) => sum + parseInt(amount.amount),
                0
              );
              this.paid = y.reduce(
                (sum, amount) => sum + parseInt(amount.amount),
                0
              );
            }
          })
          .catch(err => {
            // console.log(err);
            this.PageLoad = true;
            this.hideDiv = false;
            // this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    moveToAgreement(id) {
      // this.$router.push({
      //   path: `/dashboard/rent/${id}`,
      // });
      // const pin = this.switchData.propertyid
      window.localStorage.setItem(
        "switchable",
        JSON.stringify(this.switchableData)
      );

      this.$router.push({ path: `/dashboard/switchagreement/${id}` });
    },
    getSwitchableData: async function() {
      this.hideDiv = true;
      const switchId = this.$route.params.id;
      try {
        Api.getRequest(`getonepropertyfromswitchtable/${switchId}`)
          .then(res => {
            // console.log("reeeeeeeeeeeees", res);
            this.hideDiv = false;
            this.switchData = res.data.switch_payload;
            const rentdate = new Date(this.switchData.duedate);
            // console.log("Rented date:", rentdate);
            const currentDate = new Date();
            // console.log("Current Date", currentDate);
            const timeDiff = rentdate.getTime() - currentDate.getTime();
            const daysDiff = timeDiff / (1000 * 3600 * 24);
            // console.log("Days left for payment:", Math.round(daysDiff));
            // this.nextRentDays = parseInt(daysDiff);
            if (Math.round(daysDiff) <= 0) {
              this.nextRentDays = 0;
            } else {
              this.nextRentDays = parseInt(daysDiff);
            }
            // this.nextRentDays = parseInt(daysDiff);
            this.fetchPropertySchdeule();
          })
          .catch(err => {
            if (err.message === "Network Error") {
              this.loader = false;
              //  console.log("Network bugggggggggggggggg", err);
            }
            this.loader = false;
          });
      } finally {
        // console.log("closed");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.activeNewPlan {
  background: $yellow !important;
  .plan_duration {
    color: $black2 !important;
  }
}

.holder {
  height: 100%;
}
.wrap_row {
  margin-top: 72px;
}
.property_details_holder {
  position: relative;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 80px 25px;
  max-height: fit-content;
  height: 100%;
  .row {
    align-items: center;
  }
}

.plan_sign {
  position: absolute;
  display: flex;
  top: 0;
  div:first-child {
    background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
      #ffffff;
    border-radius: 0px 0px 10px 10px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    width: 126px;
    height: 45px;
    margin-right: 9px;
    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      align-items: center;
      color: $secondary;
      padding-top: 8px;
      margin-bottom: 4px;
    }
    p:nth-child(2) {
      font-family: Gotham;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 13px;
      color: $secondary;
    }
  }
  div:nth-child(2) {
    border: 1px solid $primary;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    width: 89px;
    height: 45px;
    p:first-child {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      align-items: center;
      color: $primary;
      padding-top: 8px;
      margin-bottom: 4px;
    }
    p:nth-child(2) {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 13px;
      color: $primary;
    }
  }
}

.property_img {
  img {
    // max-width: 224px;
    width: 100%;
    border-radius: 5px;
  }
}

.property_details {
  h3 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    // margin-bottom: 0;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    text-align: left;
    margin-bottom: 0;
  }
  h4 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 50px;
    color: $black;
    text-align: left;
    margin-bottom: 0;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: $black;
    text-align: left;
    margin-bottom: 0;
    span {
      font-weight: 600;
      margin-left: 5px;
    }
  }
}

.plan_holder {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 52px;
  max-height: 471px;
  height: 100%;
}

.plan_btn {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  box-shadow: none;
  outline: 0;
  align-items: center;
  margin-bottom: 23px;
}

.active_plan {
  background: $primary !important;
  width: 100%;
  height: 50px;
  box-shadow: none;
  outline: 0;
  align-items: center;
  margin-bottom: 23px;
  text-transform: capitalize !important;
  display: flex;
  justify-content: space-between;
  span {
    color: $secondary !important;
    text-transform: capitalize;
  }
}

.plan_amount_btn {
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  // line-height: 50px;
  color: $black;
}

.plan_duration {
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase !important;
  // line-height: 25px;
  letter-spacing: -0.05em;
  color: #c4c4c4;
}

// .plan_icon {

// }

.proceed_btn {
  background: $primary;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: $secondary;
  width: 100%;
  margin-bottom: 45px;
  height: 50px;
  outline: 0;
  box-shadow: none;
}

// PAYMENT SCHEDULE
.payment_schedule_holder {
  margin-top: 30px;
  margin-bottom: 27px;
}

.schedule_header {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
  }
}

.schedule_period {
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 17px;
    h6 {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
      margin-bottom: 0;
    }
    small {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: $black;
    }
  }
}

.green_pay {
  color: #2fc643 !important;
}

.schedule_footer {
  background: linear-gradient(180.48deg, #000000 -92.86%, #0033ea 130.64%),
    #ffffff;
  border-radius: 0px 0px 10px 10px;
  // transform: matrix(1, 0, 0, 1, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px 7px 21px;
  h5 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
  }
  h3 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0;
    text-align: left;
  }
  h4 {
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;
  }
}

.color__item {
  &:nth-of-type(1),
  &:nth-child(2n + 1) {
    background: linear-gradient(0deg, #efefef, #efefef),
      linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%) !important;
  }
  &:nth-of-type(2),
  &:nth-child(2n + 2) {
    background: $secondary !important;
  }
}
// PAYMENT SCHEDULE

@media screen and (max-width: 599px) {
  .wrap_row {
    margin-top: 25px;
  }
  .property_details {
    margin-top: 20px;
  }
  .property_details_holder {
    // margin-bottom: 25px;
  }

  .plan_holder {
    margin-top: 20px;
  }

  // payment schedule
  .schedule_period {
    div {
      h6 {
        font-size: 10px;
      }
      small {
        font-size: 10px;
      }
    }
  }
  .schedule_footer {
    flex-direction: column-reverse;
  }
  // Payment schdeule
}
</style>
